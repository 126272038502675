<!--
 * @Author: 刘格优
 * @Date: 2020-06-22 12:58:32
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-06-24 13:07:54
--> 
<!--  -->
<template>
  <div>
    <div v-show="!isNull">
      <md-scroll-view class="scroll"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      :is-prevent="false"
                      @end-reached="loadMorechange">
        <div class="all"
             v-if="type == '1'">
          <div class="poster"
               v-for="(item, index) in list"
               :key="index"
               @click="todetail(item)">
            <div class="itembox">
              <div class="top">
                <img src="@/assets/xxb/wd6.png"
                     alt="" />
                <!-- <span>{{
                  item.question.length > 16
                    ? item.question.substr(0, 15) + "..."
                    : item.question
                }}</span> -->
                <div class="side">
                  <p>{{
                  item.question.length > 16
                    ? item.question.substr(0, 15) + "..."
                    : item.question
                }}</p>
                </div>
              </div>
              <p class="desc">{{item.questiondesc.length > 16
                    ? item.questiondesc.substr(0, 15) + "..."
                    : item.questiondesc}}</p>
              <div class="middle"
                   v-if="item.answerphone">
                <img :src="item.headimg"
                     alt=""
                     v-if="item.answerempno != 'ADMIN'"
                     class="noadmin">
                <!-- <img src="@/assets/xxb/gf.png"
                     alt=""
                     v-else
                     class="admin"> -->
                <div v-else
                     class="hltx">
                  <img src="@/assets/xxb/gft.png"
                       alt=""
                       class="admin">
                  <span> 汇立天下</span>

                  <img src="@/assets/xxb/gfimg.png"
                       alt=""
                       class="right">
                </div>

                <span class="blue"
                      v-if="item.answerempno != 'ADMIN'">{{ item.answerphone }}</span>
              </div>
              <div class="words">
                {{ item.answer }}
              </div>
              <div class="bot">
                <div class="left">
                  {{ item.showtime }}
                </div>
                <div class="right">
                  <img src="@/assets/xxb/wd5.png"
                       alt="" />
                  <span>{{ item.answernum }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="type == '2'"
             class="all">
          <div class="poster"
               v-for="(item, index) in list"
               :key="index"
               @click="todetail(item)">
            <div class="itembox">
              <div class="top">
                <img src="@/assets/xxb/wd6.png"
                     alt="" />
                <!-- <span>{{
                  item.question.length > 16
                    ? item.question.substr(0, 15) + "..."
                    : item.question
                }}</span> -->
                <div class="side">
                  <p>{{
                  item.question.length > 16
                    ? item.question.substr(0, 15) + "..."
                    : item.question
                }}</p>
                </div>
              </div>
              <p class="desc">{{item.questiondesc.length > 16
                    ? item.questiondesc.substr(0, 15) + "..."
                    : item.questiondesc}}</p>
              <div class="xxmiddle"
                   v-if="item.type == 'answer'">
                <img :src="item.headimg"
                     alt=""
                     v-if="item.empno != 'ADMIN'"
                     class="noadmin">
                <!-- <img src="@/assets/xxb/gf.png"
                     alt=""
                     v-else
                     class="admin"> -->
                <div v-else
                     class="hltx">
                  <img src="@/assets/xxb/gft.png"
                       alt=""
                       class="admin">
                  <span> 汇立天下</span>

                  <img src="@/assets/xxb/gfimg.png"
                       alt=""
                       class="right">
                </div>
                <span class="blue"
                      v-if="item.empno != 'ADMIN'">{{ item.phone }}</span>
                <span class="huise">评论了你</span>
              </div>
              <div class="xxmiddle"
                   v-if="item.type == 'reply'">
                <img :src="item.headimg"
                     alt=""
                     v-if="item.empno != 'ADMIN'"
                     class="noadmin">
                <img src="@/assets/xxb/gf.png"
                     alt=""
                     v-else
                     class="admin">
                <span class="blue"
                      v-if="item.empno != 'ADMIN'">{{ item.phone }}</span>
                <span class="huise">回复了你</span>
              </div>
              <div class="xxwords">
                {{item.type == 'answer'? item.answer: item.reply}}
              </div>
              <div class="xxbot">
                <div class="left">
                  {{ item.showtime }}
                </div>
                <!-- <div class="right">
                  <img src="@/assets/xxb/wd5.png"
                       alt="" />
                  <span>{{ item.answernum }}</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import { myQuestion, myMsg } from "@/api/xxb/index";
import { getStorage } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
export default {
  mixins: [loadMorechange],
  data () {
    return {
      list: [],
      user: {},
      type: "",
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      isNull: true,
    };
  },
  created () {
    this.user = getStorage("u_s", {});
    this.type = this.$route.query.type;
    this.getData();
  },
  mounted () {
    document.querySelector(".scroll").style.height = `${document.body
      .clientHeight}px`;
  },
  methods: {
    getData () {
      if (this.type == '1') {
        document.title = '我的提问'
        let data = {
          // empno: this.user.empno,
          page: this.listpageNo,
          size: this.listsize,
          questioninfo: "",
          empno: this.user.empno
        };
        myQuestion(data).then(res => {
          this.list =
            this.list.length == 0
              ? res.data.data.rows
              : this.list.concat(res.data.data.rows);
          if (this.list.length == 0) {
            this.isNull = true;
          } else {
            this.isNull = false;
          }
          this.listtotal = res.data.data.total;
          this.listtotalPage = res.data.data.totalpage;
          if (this.listtotalPage <= this.listpageNo) {
            this.listFinished = true;
          } else {
            this.listFinished = false;
            this.$refs.scrollView.finishLoadMore();
          }
          this.loadflag = true;
        });
      } else {
        document.title = '我的消息'
        let data = {
          page: this.listpageNo,
          size: this.listsize,
          empno: this.user.empno
        };
        myMsg(data).then((res) => {
          this.list =
            this.list.length == 0
              ? res.data.rows
              : this.list.concat(res.data.rows);
          if (this.list.length == 0) {
            this.isNull = true;
          } else {
            this.isNull = false;
          }
          this.listtotal = res.data.total;
          this.listtotalPage = res.data.totalpage;
          if (this.listtotalPage <= this.listpageNo) {
            this.listFinished = true;
          } else {
            this.listFinished = false;
            this.$refs.scrollView.finishLoadMore();
          }
          this.loadflag = true;
        })
      }

    },
    todetail (item) {
      this.$router.push({
        path: `/answerdetail?questioncode=${item.questioncode}`
      });
    }
  }
}

</script>
<style scoped lang="stylus">
.all {
  padding: 0.5rem;
}

.poster {
  padding: 0.4rem 0;
  border-bottom: 1px solid #f1f1f1;

  .itembox {
    .top {
      display: flex;
      align-items: center;

      img {
        width: 0.6rem;
        height: auto;
      }

      .side {
        padding-left: 0.2rem;

        p {
          color: #383838;
          font-size: 0.43rem;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0.8rem;
        }
      }
    }

    .desc {
      color: #8d9095;
      font-size: 0.33rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 0.6rem;
      padding-left: 0.8rem;
    }

    .middle {
      padding: 0.2rem 0;
      display: flex;
      align-items: center;

      .blue {
        color: #4EA6E6;
        font-size: 0.38rem;
        padding-right: 0.2rem;
        padding-left: 0.2rem;
      }

      .huise {
        color: #999999;
        font-size: 0.4rem;
      }

      .noadmin {
        width: 0.7rem;
        border-radius: 50%;
      }
    }

    .xxmiddle {
      padding-top: 0.3rem;
      display: flex;
      align-items: center;

      .blue {
        color: #4EA6E6;
        font-size: 0.38rem;
        padding-right: 0.2rem;
        padding-left: 0.2rem;
      }

      .huise {
        color: #999999;
        font-size: 0.4rem;
      }

      .noadmin {
        width: 0.7rem;
        border-radius: 50%;
      }
    }

    .xxwords {
      padding-top: 0.2rem;
    }

    .xxbot {
      padding-top: 0.2rem;
      color: #AAAAAA;

      .left {
        font-size: 0.35rem;
      }
    }

    .words {
      color: #383838;
      font-size: 0.39rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 0.7rem;
      margin-bottom: 0.1rem;
    }

    .bot {
      display: flex;
      justify-content: space-between;

      .left {
        color: #AAAAAA;
        font-size: 0.35rem;
      }

      .right {
        color: #767676;
        font-size: 0.35rem;
        display: flex;
        align-items: center;

        img {
          width: 0.45rem;
          height: auto;
          margin-right: 0.15rem;
        }
      }
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

.hltx {
  display: flex;
  align-items: center;

  .admin {
    width: 0.7rem;
    border-radius: 50%;
  }

  .right {
    width: 0.8rem;
    padding-right: 0.2rem;
  }

  span {
    color: #4EA6E6;
    font-size: 0.38rem;
    padding-left: 0.2rem;
  }
}
</style>